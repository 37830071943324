<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col cols="12" md="8">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-if="current"
          :disabled="!!current.deletedAt"
        >
          <purchase-order-form
            v-if="current"
            :current="current"
            :generating="generating"
            :computedValue="computedValue"
            :computedVat="computedVat"
            :computedDelivery="computedDelivery"
            :computedTotal="computedTotal"
            :requiredFields="requiredFields"
            :rates="rates"
            :comptaUpdates="rebuild" /></v-form
      ></v-col>
      <v-col cols="12" md="4">
        <div style="position: sticky; top: 76px">
          <div v-if="current && rebuild">
            <div class="title">Modifications comptabilité</div>
            <v-text-field
              outlined
              dense
              label="C/C finance"
              class="mt-4"
              v-model="current.ccFinance"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Pil"
              v-model="current.pil"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Filière"
              v-model="current.filiere"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              v-model="comment"
            ></v-textarea>
          </div>
          <v-btn outlined block :disabled="generating" @click="$router.go(-1)"
            >Annuler</v-btn
          >
          <v-btn
            class="mt-2"
            outlined
            block
            :loading="generating"
            @click="
              rebuild
                ? updateOrderForCompta(
                    {
                      rates,
                      ...current,
                      computedTotal,
                    },
                    comment
                  )
                : updateOrder({
                    rates,
                    ...current,
                    computedTotal,
                  })
            "
            :disabled="
              (current && !this.requiredFields) ||
              !this.valid ||
              !!current.deletedAt
            "
            >Mettre à jour</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PurchaseOrderForm from "@/components/PurchaseOrderForm";
import axios from "axios";

export default {
  components: { PurchaseOrderForm },
  async mounted() {
    const poFromStorage = JSON.parse(localStorage.getItem("po"));
    const supplierFromStorage = JSON.parse(localStorage.getItem("supplier"));
    if (Object.keys(poFromStorage).length) {
      this.current = poFromStorage;
      localStorage.setItem("po", JSON.stringify({}));
    }
    if (Object.keys(supplierFromStorage).length) {
      const supplier = supplierFromStorage;
      this.current.supplier.email = supplier.email;
      this.current.supplier.address =
        `${supplier.addressOne}\n${supplier.addressTwo}\n${supplier.zip} ${supplier.city}\n${supplier.country}`
          .replace(/(\r\n|\r|\n){2}/g, "$1")
          .replace(/(\r\n|\r|\n){3,}/g, "$1\n");
      this.current.supplier.name = supplier.name;
      this.current.supplier.contact = supplier.contact;
      this.current.supplier.phone = supplier.phone;
      localStorage.setItem("supplier", JSON.stringify({}));
    }
    const existingOrder = JSON.parse(localStorage.getItem("order"));
    const snackbar = JSON.parse(localStorage.getItem("snackbar"));
    this.snackbar = snackbar;
    if (Object.keys(existingOrder).length) {
      this.current = existingOrder;
    } else {
      if (!this.current) {
        const { rebuild } = this.$route.query;
        this.rebuild = rebuild === "true";
        const {
          data: { order, comment },
        } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/validations/${this.$route.params.validation_id}`,
          params: rebuild !== undefined ? { rebuild } : {},
        });
        this.current = order;
        this.comment = comment;
      }
    }
    window.addEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
      localStorage.setItem("order", JSON.stringify({}));
    });
    if (this.current && !this.current.rates) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/exchange-rates`,
      });
      const chfRates = {};
      Object.keys(data.rates).map(
        (k) => (chfRates[k] = data.rates[k] / data.rates["CHF"])
      );
      this.rates = { ...chfRates, date: data.timestamp };
    } else {
      this.rates = this.current.rates;
    }
  },
  beforeDestroy() {
    window.removeEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
      localStorage.setItem("order", JSON.stringify({}));
    });
    localStorage.setItem(
      "snackbar",
      JSON.stringify({
        visible: false,
        color: "",
        text: "",
      })
    );
    localStorage.setItem("order", JSON.stringify({}));
  },

  data: () => ({
    current: undefined,
    comment: "",
    rebuild: false,
    generating: false,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    valid: false,
    rates: {},
  }),
  methods: {
    async updateOrderForCompta(order, comment) {
      this.generating = true;
      try {
        await axios({
          method: "put",
          url: `${process.env.VUE_APP_API_URI}/validations/${this.$route.params.validation_id}?action=updateForCompta`,
          data: {
            order,
            comment,
          },
        });
        this.$router.go(-1);
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de mise à jour du bon de commande",
        };
      } finally {
        this.generating = false;
      }
    },
    async updateOrder(order) {
      this.generating = true;
      try {
        await axios({
          method: "put",
          url: `${process.env.VUE_APP_API_URI}/validations/${this.$route.params.validation_id}`,
          data: {
            order,
          },
        });
        this.$router.go(-1);
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de mise à jour du bon de commande",
        };
      } finally {
        this.generating = false;
      }
    },
  },
  computed: {
    computedValue() {
      if (this.current) {
        return this.current.currency === "CHF"
          ? this.current.value
          : this.current.value / this.rates[this.current.currency];
      }
      return "";
    },
    computedDelivery() {
      if (this.current.deliveryIncluded) {
        return 0;
      } else {
        if (this.current.deliveryCurrency === "CHF") {
          return this.current.deliveryFees;
        } else {
          return (
            this.current.deliveryFees /
            this.rates[this.current.deliveryCurrency]
          );
        }
      }
    },
    computedVat() {
      return this.current.vatIncluded
        ? 0
        : (this.computedValue * this.current.vat) / 100;
    },
    computedTotal() {
      return this.computedValue + this.computedDelivery + this.computedVat;
    },
    requiredFields: function () {
      if (this.current) {
        if (this.computedValue < 5000) {
          return (
            !!this.current.supplier.name &&
            !!this.current.delivery &&
            !!this.current.value &&
            !!this.current.year &&
            !!this.current.creatorTla
          );
        } else {
          return (
            !!this.current.supplier?.name &&
            !!this.current.delivery &&
            !!this.current.value &&
            !!this.current.year &&
            !!this.current.creatorTla &&
            !!this.current.offer
          );
        }
      }
      return "";
    },
  },
};
</script>
