<template>
  <v-text-field
    ref="input"
    v-currency="{
      currency: currency,
      valueRange: { min: 0 },
      allowNegative: false,
      locale: 'fr-CH',
      distractionFree: true,
    }"
    :value="formattedValue"
    @change="onChange"
    @input="onInput"
    outlined
    dense
    :hint="
      currency !== 'CHF'
        ? `Estimation : CHF ${(value / this.rates[currency]).toFixed(2)}`
        : ''
    "
    :persistent-hint="currency !== 'CHF' && !!value"
    :rules="rules"
    :label="label"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: "VCurrencyField",
  props: ["value", "currency", "rates", "rules", "label", "disabled"],
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
    onInput(value) {
      this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
  },
};
</script>
